export enum TaskApiEnum {
  GetTask = '/tasks/:id/',
  ResolveTask = '/tasks/:id/resolution/',
  TakeTask = '/tasks/:id/execution/',
  GetWorkPerformedAct = '/tasks/:id/completion_certificate/',

  CreateTaskCompletionDocuments = '/tasks/:id/completion-documents',
  GetTaskCompletionDocuments = '/tasks/:id/completion-documents',

  GetTasks = '/tasks/',
  GetTaskListMap = '/tasks/map/',

  GetTaskCounters = '/tasks/counters/',

  UpdateTaskWorkGroup = '/tasks/:id/work-group/',
  DeleteTaskWorkGroup = '/tasks/:id/work-group/',

  UpdateTaskAssignee = '/tasks/:id/assignee/',

  CreateTaskComment = '/tasks/:id/comments/',
  GetTaskCommentList = '/tasks/:id/comments/',

  CreateReclassificationRequest = '/tasks/:id/reclassification-requests/',
  GetReclassificationRequest = '/tasks/:id/reclassification-request/',

  CreateTaskSuspendRequest = '/tasks/:id/suspend_request/',
  DeleteTaskSuspendRequest = '/tasks/:id/suspend-request/',

  GetTaskJournal = '/tasks/:id/journal/',
  GetTaskJournalCsv = '/tasks/:id/journal/csv/',

  GetSubTasks = '/tasks/:id/subtasks/',
  CreateSubTask = '/tasks/:id/subtasks/',

  CreateTaskCallingReason = '/tasks/:taskId/initiation-reasons',
  DeleteTaskCallingReason = '/initiation-reasons/:id',

  CreateTaskCompletedWork = '/tasks/:taskId/completed-works',
  DeleteTaskCompletedWork = '/completed-works/:id',
}

export enum TaskApiTagEnum {
  Task = 'Task',
  Tasks = 'Tasks',
  TaskCounters = 'TaskCounters',
}

export enum TaskApiTriggerEnum {
  GetTasks = 'getTasks',
  GetTaskListMap = 'getTaskListMap',
  GetTaskCounters = 'getTaskCounters',
  GetTask = 'getTask',
  GetWorkPerformedAct = 'getTaskWorkPerformedAct',
  ResolveTask = 'resolveTask',
  TakeTask = 'takeTask',
  CreateTaskComment = 'createTaskComment',
  GetTaskCommentList = 'getTaskCommentList',
  GetSubTasks = 'getSubTasks',
  CreateSubTask = 'createSubTask',
  GetTaskCompletionDocuments = 'getTaskCompletionDocuments',
}
