export const logoutErrMsg = 'Ошибка выхода из системы'

export const loginWrongDataErrMsg = 'Неверный логин и/или пароль'

export const incorrectPasswordErrMsg =
  'Пароль не соответствует требованиям: не менее 8 символов, минимум одна цифра, минимум одна латинская буква в верхнем и нижнем регистре, минимум один из спецсимволов ~!@#$%^&*_-+=`|\\(){}[]:;"\'<>,.?/'

export const loginErrMsg = 'Ошибка входа в систему'

export const updatePasswordSuccessMsg = 'Пароль успешно изменен'
export const updatePasswordErrMsg = 'Ошибка обновления пароля'
