import ProtectedRoute from 'features/auth/components/ProtectedRoute'
import React from 'react'
import { Navigate, RouteObject } from 'react-router-dom'

import { CommonRouteEnum } from 'configs/routes'

const TasksPage = React.lazy(() => import('features/task/pages/TasksPage'))

export const route: Readonly<RouteObject> = {
  path: CommonRouteEnum.DesktopTasks,
  children: [
    {
      index: true,
      element: <Navigate to={CommonRouteEnum.DesktopTaskList} />,
    },
    {
      path: CommonRouteEnum.DesktopTaskList,
      element: <ProtectedRoute component={<TasksPage />} />,
    },
  ],
}
