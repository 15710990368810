import { Col, Layout, Row, Space } from 'antd'
import pick from 'lodash/pick'
import React, { FC } from 'react'

import LogoutButton from 'features/auth/components/LogoutButton'
import { useUserMeState } from 'features/user/hooks'
import { getUserAbbr } from 'features/user/utils'

import { AppVersionStyled } from 'components/AppVersion'
import UserAvatar from 'components/Avatars/UserAvatar'

import { useAndroidInterface } from 'shared/hooks/useAndroidInterface'

const { Header } = Layout

const PrivateHeader: FC = () => {
  const { data: userMe } = useUserMeState()
  const androidInterface = useAndroidInterface()

  return (
    <Header data-testid='private-header'>
      <Row justify='space-between'>
        {userMe && (
          <Col>
            <UserAvatar
              data-testid='detailed-user-avatar'
              size='large'
              abbr={getUserAbbr(pick(userMe, 'firstName', 'lastName'))}
              src={userMe.avatar}
            />
          </Col>
        )}

        <Col>
          <Space size='middle'>
            {androidInterface?.appVersion && (
              <AppVersionStyled>{androidInterface.appVersion()}</AppVersionStyled>
            )}

            <LogoutButton />
          </Space>
        </Col>
      </Row>
    </Header>
  )
}

export default PrivateHeader
