import { EllipsisConfig } from 'antd/es/typography/Base'

export const APP_NAME = 'Obermeister-ITSM'

export const DEFAULT_DEBOUNCE_VALUE = 150
export const DEFAULT_MODAL_WIDTH = '100%'

export const CANCEL_TEXT = 'Отмена'
export const CREATE_TEXT = 'Создать'
export const SAVE_TEXT = 'Сохранить'
export const UPDATE_TEXT = 'Обновить'
export const ADD_TEXT = 'Добавить'
export const SEND_TEXT = 'Отправить'
export const CONFIRM_TEXT = 'Подтвердить'
export const NO_ASSIGNEE_TEXT = 'Не назначен'

export const HYPHEN = '-'

export const commonEllipsisConfig: Pick<EllipsisConfig, 'rows' | 'expandable'> = {
  rows: 4,
  expandable: true,
}

export const unknownErrMsg = 'Неизвестная ошибка'
