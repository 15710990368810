import { useMount } from 'ahooks'
import { useState } from 'react'

export const useAndroidInterface = (): typeof window.AndroidInterface => {
  const [androidInterface, setAndroidInterface] = useState<typeof window.AndroidInterface>()

  useMount(() => {
    if (!!window.AndroidInterface?.appVersion && !!window.AndroidInterface?.downloadBase64File) {
      setAndroidInterface(window.AndroidInterface)
    } else {
      console.error('AndroidInterface was not added to "window" object')
    }
  })

  return androidInterface
}
