import { camelizeKeys } from 'humps'

import { UserPermissionsEnum } from 'features/user/constants'
import { UserModel } from 'features/user/models'

import { Camelize, Writeable } from 'shared/types/utils'

export type MatchedUserPermissions = Readonly<
  Camelize<Partial<Record<Lowercase<UserPermissionsEnum>, boolean>>>
>

export const getPermissionsObj = (
  user: UserModel,
  permissions: UserPermissionsEnum[],
): MatchedUserPermissions =>
  camelizeKeys(
    permissions.reduce<Writeable<MatchedUserPermissions>>((acc, perm) => {
      const key = perm.toLowerCase() as keyof MatchedUserPermissions
      acc[key] = user.permissions.includes(perm)
      return acc
    }, {}),
  )

export const checkEveryPermissionAllowed = (permissions: MatchedUserPermissions): boolean =>
  Object.values(permissions).every(Boolean)

export const hasPermissions = (user: UserModel, permissions: UserPermissionsEnum[]) =>
  checkEveryPermissionAllowed(getPermissionsObj(user, permissions))
