import { QrcodeOutlined } from '@ant-design/icons'
import styled from 'styled-components'

import { colorStyles, fontSizeStyles } from './styles'
import { IconProps } from './types'

const QrcodeIconStyled = styled(QrcodeOutlined)<Pick<IconProps, '$size' | '$color'>>`
  ${fontSizeStyles}
  ${colorStyles}
`

export default QrcodeIconStyled
