import { Layout } from 'antd'
import moment from 'moment-timezone'
import React, { FC, useEffect } from 'react'
import { Outlet } from 'react-router-dom'

import { useIsLoggedIn } from 'features/auth/hooks'
import { useGetUserMe } from 'features/user/hooks'

import PrivateHeader from 'components/Headers/PrivateHeader'
import LoadingArea from 'components/LoadingArea'
import Spinner from 'components/Spinner'

import { ContentStyled } from './styles'

const HomeLayout: FC = () => {
  const isLoggedIn = useIsLoggedIn()

  const { data: userMe, isFetching: userMeIsFetching } = useGetUserMe({ skip: !isLoggedIn })

  useEffect(() => {
    if (userMe?.timezone) {
      moment.tz.setDefault(userMe.timezone)
    }
  }, [userMe?.timezone])

  return (
    <Layout>
      <LoadingArea
        data-testid='home-layout-loading'
        isLoading={userMeIsFetching}
        area='global'
        size='large'
      >
        <PrivateHeader />

        <ContentStyled>
          <React.Suspense fallback={<Spinner area='parent' size='large' />}>
            <Outlet />
          </React.Suspense>
        </ContentStyled>
      </LoadingArea>
    </Layout>
  )
}

export default HomeLayout
