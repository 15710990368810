import { ApiRequestMessages } from 'shared/types/messages'

export const getTaskErrMsg = 'Ошибка получения заявки'
export const getTasksErrorMsg = 'Ошибка получения списка заявок'
export const getTaskListMapErrorMsg = 'Ошибка получения списка заявок для карты'

export const getTaskWorkPerformedActMessages: ApiRequestMessages<'commonError'> = {
  commonError: 'Ошибка формирования акта выполненных работ',
}

export const takeTaskErrMsg = 'Ошибка взятия задачи в работу'
export const resolveTaskErrMsg = 'Невозможно выполнить заявку'

export const createSubTaskMessages: ApiRequestMessages<'commonError'> = {
  commonError: 'Не удалось создать задание',
}
export const getSubTasksErrMsg = 'Не удалось получить список заданий'

export const getTaskCompletionDocumentsErrMsg =
  'Ошибка получения данных о причинах вызова, выполненных работах, потраченном времени и связанных перемещениях оборудования по заявке itsm'

export const createTaskCompletionDocumentsErrMsg = 'Ошибка формирования акта о выполненных работах'

export const createTaskCallingReasonErrMsg = 'Ошибка создания причины вызова'
export const deleteTaskCallingReasonErrMsg = 'Ошибка удаления причины вызова'

export const createTaskCompletedWorkErrMsg = 'Ошибка создания проведенных работ'
export const deleteTaskCompletedWorkErrMsg = 'Ошибка удаления проведенных работ'
