export enum UserApiEnum {
  GetUsers = '/users/',
  UpdateUser = '/users/:id/',
  GetUserMe = '/users/me/',
  GetUserMeCode = '/users/me/code/',
  UpdateUserStatus = '/users/:id/status/',
  GetWarehouseMSI = '/users/:id/mobile-warehouse/',
  GetUserActions = '/users/:id/actions',
}

export enum UsersApiTagEnum {
  UserActions = 'UserActions',
}
