import { generatePath } from 'react-router-dom'

import { UserApiEnum } from 'features/user/constants'

import { IdType } from 'shared/types/common'
import { generateApiPath } from 'shared/utils/api'
import { appendSlashAtEnd } from 'shared/utils/string'

export const updateUserUrl = (userId: IdType): string =>
  appendSlashAtEnd(generatePath(UserApiEnum.UpdateUser, { id: String(userId) }))

export const updateUserStatusUrl = (userId: IdType): string =>
  appendSlashAtEnd(generatePath(UserApiEnum.UpdateUserStatus, { id: String(userId) }))

export const getWarehouseMSIUrl = (userId: IdType): string =>
  appendSlashAtEnd(generatePath(UserApiEnum.GetWarehouseMSI, { id: String(userId) }))

export const getUserActionsUrl = (userId: IdType): string =>
  generateApiPath(UserApiEnum.GetUserActions, { id: String(userId) })
