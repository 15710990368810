import React from 'react'
import { Navigate, RouteObject } from 'react-router-dom'

import { route as authRoute } from 'features/auth/routes.config'
import { route as taskRoute } from 'features/task/routes.config'

import ErrorBoundary from 'components/ErrorBoundary'
import HomeLayout from 'components/Layouts/HomeLayout'

import { CommonRouteEnum } from './constants'

export const routes: RouteObject[] = [
  {
    path: CommonRouteEnum.Root,
    errorElement: <ErrorBoundary />,
    children: [
      {
        index: true,
        element: <Navigate to={CommonRouteEnum.Home} />,
      },

      authRoute,

      {
        path: CommonRouteEnum.Home,
        element: <HomeLayout />,
        children: [
          {
            index: true,
            element: <Navigate to={CommonRouteEnum.DesktopTaskList} />,
          },
          taskRoute,
        ],
      },
    ],
  },
]
